@forward '@nebular/theme/styles/theming';
@use '@nebular/theme/styles/theming' as *;
@use '@nebular/theme/styles/themes/corporate';

$nb-enabled-themes: (corporate);
$nb-enable-css-custom-properties: false;

//Фикс бага у nebular https://github.com/akveo/ngx-admin/issues/2076
.cdk-global-scrollblock {
  position: static !important;
}
