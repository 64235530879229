/* You can add global styles to this file, and also import other style files */
@use 'theme' as *;
@use '@nebular/theme/styles/globals' as *;

@include nb-install() {
  @include nb-theme-global();
}

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/code';
@import 'bootstrap/scss/forms';
@import '@nebular/theme/styles/global/breakpoints';

.scrollable-container {
  scrollbar-gutter: stable;
}

.hide-lg {
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.hide-md {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.table {
  &__container {
    overflow: auto;
  }

  &__row {
    cursor: pointer;
    &:hover {
      background-color: #f7f9fc !important;
    }
  }

  &__cell {
    &_xs {
      width: 100px;
    }

    &_sm {
      width: 120px;
    }

    &_md {
      width: 140px;
    }

    &_lg {
      width: 160px;
    }

    &_xl {
      width: 180px;
    }
  }
}
